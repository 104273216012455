<template>

    <div class="yj-conten" style="background-color:#fff;width:calc(100% - 32px);min-width:fit-content">
        <a-layout-content style="padding:0;">
            <div class="yj-conten " style="background: #fff;padding: 20px ;margin: 0;">

                <a-row type="flex">
                    <a-col flex="220px" style="text-align:center;">
                        <img src="/image/honor/1.png" style="width:160px;" />
                        <p style="margin:10px 0 0">{{tableData.Rule.HonorName}}</p>
                    </a-col>
                    <a-col flex="auto">

                        <!--<a-form-model-item :label="tableData.Rule.HonorName" v-for="item in tableData" :key="item.Rule.ID">-->
                        <a-form-model-item >
                            <a-form-model layout='inline'>

                                <a-form-model-item :label="itemIndex.IndexName" prop="ReadTarget" v-for="itemIndex in tableData.RuleIndex" :key="itemIndex.ID">
                                    <a-input-number :min="0" placeholder="" style="width:70px" v-model="itemIndex.LimitCount" />
                                </a-form-model-item>
                                <br /> <br />
                                <a-form-model-item label="奖励金额(元)" prop="ReportTarget">
                                    <a-input-number :min="0" placeholder="" style="width:70px" v-model="tableData.Rule.Cash" />
                                </a-form-model-item>
                            </a-form-model>
                        </a-form-model-item>




                    </a-col>
                </a-row>
               



                <a-form-model-item label="" class="textal_c">
                    <div v-if="this.$route.query.rowType !='select'">
                        <a-button type="primary" @click="save();">
                            保存
                        </a-button>
                    </div>
                </a-form-model-item>

            </div>
        </a-layout-content>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    // import util from "@/Plugin/util.js";
    export default {
        name: "M2Activity_List",
        data() {
            return {
                tableData: null,
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 2 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 },
                    },
                },
            };
        },
        props: {//组件属性
        },
        methods: {//方法


            getTableData: function () {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerHonor/GetPartnerHonorItem",
                    data: {
                        id: self.$route.query.ID
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data;
                        console.log("self.tableData");
                        console.log(self.tableData);
                    }
                };
                http.Post(op);
            },
            save: function () {
                var self = this;

                console.log(self.tableData);
                var isConfig = false;
             
                for (var j = 0; j < self.tableData.RuleIndex.length; j++) {
                    if (self.tableData.RuleIndex[j].LimitCount != 0) {
                        isConfig = true;
                        break;
                    }
                }

                if (!isConfig) {
                    alert("请配置" + self.tableData.Rule.HonorName + "等级规则！");
                    return;
                }


                var op = {
                    url: "/PartnerModule/PartnerHonor/SaveSinglePartnerHonorItem",
                    data: {
                        model: self.tableData
                    },
                    OnSuccess: function (data) {

                        console.log(data);
                        self.$message.success('保存成功', 1.5, function () { });
                        self.$router.push({
                            name: "PartnerHonor_List"
                        });
                        //self.tableData = data.data;
                        //console.log(self.tableData);             
                    }
                };
                http.Post(op);



            }

        },

        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        mounted: function () {//生命周期，组装完成
            this.getTableData();
        }

    };
</script>